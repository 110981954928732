import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  FormLabel,
  Group,
  IconTrash,
  IconCaretDown,
  IconCaretRight,
  IconWarning,
  Input,
  Select,
  Switch,
  Wrapper,
  Responsive,
  Textarea,
  Token,
} from '@screentone/core';
import { useAuth } from '@screentone/addon-auth-wrapper';
import Calendar, { format } from '@screentone/addon-calendar';

import PaddingColor from '../PaddingColor';
import AltTextTooltip from '../EditMetadataForm/AltTextTooltip.component';
import useConfig from '../../hooks/useConfig';
import { requiredFieldsForPublisher, slugNormalize } from '../../utils/helpers';
import { constants, helpers } from '../../utils';

import type { GraphicType, tExtendedImageMetadata, tImageValidation } from '../../types';

import styles from './EditMetadataCard.module.css';

const IMAGE_WIDTH = 340;

/**
 * The props that the EditMetadataCard accepts.
 */
interface EditMetadataCardProps {
  /** the name of the file */
  filename: string;
  /** the metadata of the image */
  metadata: tExtendedImageMetadata;
  /** the name of the file */
  height: number;
  /** the name of the file */
  width: number;
  /** the image, as a base64 encoded string */
  imageBuffer: string;
  /** a flag that indicates whether or not the bulk edit form is open */
  isBulkEditFormOpen: boolean;
  /** indicates whether or not the image is selected for bulk edit/deletion */
  isSelected: boolean;
  /** an array of indexes that represent which images are selected */
  selectedImages: number[];
  /** a function for assisting in the validation of form fields and updates the state */
  onBlur(payload: Partial<tExtendedImageMetadata>): void;
  /** a state setting function that updates the state of the image's metadata */
  onChange(payload: Partial<tExtendedImageMetadata>): void;
  /** a function that removes the image from the uploader */
  onDelete(): void;
  /** a function that selects the image for bulk metadata editing */
  onImageSelect(selected: boolean): void;
  /** a function for closing the bulk edit form */
  closeSidebar(): void;
  /** an object containing validation information for all of the metadata fields of the image */
  validation: tImageValidation;
  /** a function that fires when the image preview loads */
  onImageLoad?(img: HTMLImageElement): void;
  /** the aiCaptioning */
  aiCaptioning?: boolean;
}

/**
 * A form for updating an image's metadata on the upload flow
 */
function EditMetadataCard({
  metadata,
  filename,
  imageBuffer,
  isBulkEditFormOpen,
  isSelected,
  selectedImages,
  onBlur,
  onChange,
  onDelete,
  onImageSelect,
  closeSidebar,
  validation,
  height,
  width,
  onImageLoad,
  aiCaptioning,
}: EditMetadataCardProps) {
  const { userAccess } = useAuth();

  const {
    slug,
    caption,
    contact,
    credit,
    background,
    datePhotographed,
    altText,
    graphicType,
    headline,
    oneTimeUse,
    resizeOnly,
    uncredited,
  } = metadata;

  const {
    session: { property, SEARCH },
  } = useConfig();
  const isAdmin = userAccess('app_admin');
  const canSeeAiCaptioning = isAdmin || userAccess('developer') || userAccess(`${property}.ai_captioning`);

  const [isExpanded, setIsExpanded] = useState(true);
  const requiredFields = requiredFieldsForPublisher(property, SEARCH?.OPTIONS?.FORM_VALIDATION);
  const totalRequiredFields = Object.keys(requiredFields);
  const totalComplete = totalRequiredFields.reduce((total, field) => (total += validation[field] == false ? 1 : 0), 0);
  const [calendarError, SetCalendarError] = useState('');

  const uncredit = requiredFields?.credit || false;
  const calendarRef = useRef<HTMLInputElement | null>(null);
  const pixelLimitWarning = 'Images over 50 million total pixels will upload but likely have rendering issues.';
  const dimension = height * width;

  const getOneTimeUse = (oneTimeUseParam: boolean | undefined) => {
    const cleanCaption = caption?.replace('*** ONE-TIME USE ***', '').trim();

    return oneTimeUseParam ? cleanCaption : `*** ONE-TIME USE *** ${cleanCaption || ''}`.trim();
  };

  const getUncredited = (uncreditedParam: boolean) => {
    return uncreditedParam ? 'Uncredited' : '';
  };

  return (
    <Wrapper data-testid="metadata-card" margin={{ bottom: 'md' }}>
      {dimension >= constants.WARNING_PIXELS && (
        <Box padding={{ vertical: 'smd', horizontal: 'smd' }} attached="top" className={styles.pixelLimitWarning}>
          <Group>
            <IconWarning />
            {pixelLimitWarning}
          </Group>
        </Box>
      )}
      <Box
        padding={{ vertical: 'sm', horizontal: 'md' }}
        attached="top"
        className={!isExpanded ? styles.borderTitleBottom : ''}
      >
        <Group align="space-between">
          <Group align="space-between" padding={{ vertical: 'sm', horizontal: 'md' }}>
            <Group gap="sm">
              <FormLabel label={filename} labelPosition="right">
                <Checkbox
                  data-testid="select-img-checkbox"
                  name={`select-img-checkbox-${filename}`}
                  id={`select-img-checkbox-${filename}`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const isChecked = e.target.checked;
                    const isLastSelectedImage = isSelected && selectedImages.length === 1;

                    // close bulk edit form if no images are selected.
                    if (!isChecked && isLastSelectedImage) closeSidebar();

                    onImageSelect(isChecked);
                  }}
                  checked={isSelected}
                />
              </FormLabel>
              <Token
                data-testid="required-fields-token"
                color={totalComplete === totalRequiredFields.length ? 'emerald' : 'lava'}
              >
                {totalComplete}/{totalRequiredFields.length}
              </Token>
            </Group>
          </Group>
          <Group.Item padding={{ vertical: 'sm', horizontal: 'md' }} flex>
            <Button
              data-testid="expand-btn"
              icon={isExpanded ? IconCaretDown : IconCaretRight}
              justified="right"
              onClick={() => setIsExpanded(!isExpanded)}
              tertiary
              fullWidth
              color="gray"
            />
          </Group.Item>
        </Group>
      </Box>
      {isExpanded && (
        <Wrapper margin={{ bottom: 'md' }}>
          <Box padding={{ all: 'md' }}>
            <Responsive xs sm>
              {(matches: boolean) => (
                <Group direction={matches ? 'column' : 'row'} valign="start" wrap={false}>
                  <picture>
                    <img
                      alt={filename}
                      src={imageBuffer}
                      width={IMAGE_WIDTH}
                      onLoad={({ target }) => onImageLoad?.(target as HTMLImageElement)}
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  </picture>
                  <Group fullWidth direction="column" valign="start">
                    <FormLabel fullWidth label="Slug" required={requiredFields.slug}>
                      <Input
                        data-testid="slug-input"
                        disabled={isBulkEditFormOpen}
                        error={!!validation.slug}
                        onBlur={({ target }: { target: HTMLInputElement }) => {
                          onBlur({ slug: target.value.trimEnd() });
                        }}
                        onChange={({ target }: { target: HTMLInputElement }) =>
                          onChange({ slug: slugNormalize(target.value) })
                        }
                        placeholder="Slug Required"
                        value={slug}
                      />
                      {validation.slug && <FormHelperText error>{validation.slug}</FormHelperText>}
                    </FormLabel>
                    <FormLabel fullWidth label="Headline" required={requiredFields.headline}>
                      <Input
                        data-testid="headline-input"
                        disabled={isBulkEditFormOpen}
                        error={!!validation.headline}
                        onBlur={({ target }: { target: HTMLInputElement }) => {
                          onBlur({ headline: target.value.trimEnd() });
                        }}
                        onChange={({ target }: { target: HTMLInputElement }) =>
                          onChange({ headline: target.value.trimStart() })
                        }
                        placeholder="Headline Required"
                        value={headline}
                      />
                      {validation.headline && <FormHelperText error>{validation.headline}</FormHelperText>}
                    </FormLabel>
                    <FormLabel fullWidth label="Caption" required={requiredFields.caption}>
                      <Textarea
                        data-testid="caption-input"
                        disabled={isBulkEditFormOpen}
                        error={!!validation.caption}
                        onBlur={({ target }: { target: HTMLInputElement }) => {
                          onBlur({ caption: target.value.trimEnd() });
                        }}
                        onChange={({ target }: { target: HTMLInputElement }) =>
                          onChange({ caption: target.value.trimStart() })
                        }
                        placeholder="Caption"
                        value={caption}
                      />
                      {validation.caption && <FormHelperText error>{validation.caption}</FormHelperText>}
                    </FormLabel>

                    <Group fullWidth wrap={false} valign="start">
                      <FormLabel
                        fullWidth
                        label={
                          <Group align="space-between">
                            <FormLabel label="Credit" required={requiredFields.credit} />
                            {uncredit && (
                              <Button
                                data-testid="edit-uncredit"
                                tertiary
                                active={uncredited}
                                onClick={() => onBlur({ uncredited: !uncredited, credit: getUncredited(!uncredited) })}
                                size="sm"
                              >
                                Uncredited
                              </Button>
                            )}
                          </Group>
                        }
                      >
                        <Input
                          data-testid="credit-input"
                          disabled={isBulkEditFormOpen || uncredited}
                          error={!!validation.credit}
                          onBlur={({ target }: { target: HTMLInputElement }) => {
                            onBlur({ credit: target.value.trimEnd(), uncredited });
                          }}
                          onChange={({ target }: { target: HTMLInputElement }) => {
                            onChange({ credit: target.value.trimStart() });
                          }}
                          placeholder="Credit"
                          value={credit}
                        />
                        {validation.credit && <FormHelperText error>{validation.credit}</FormHelperText>}
                      </FormLabel>
                      <FormLabel fullWidth label="Contact" required={requiredFields.contact}>
                        <Input
                          data-testid="contact-input"
                          disabled={isBulkEditFormOpen}
                          error={!!validation.contact}
                          onBlur={({ target }: { target: HTMLInputElement }) => {
                            onBlur({ contact: target.value.trimEnd() });
                          }}
                          onChange={({ target }: { target: HTMLInputElement }) =>
                            onChange({ contact: target.value.trimStart() })
                          }
                          placeholder="Contact"
                          value={contact}
                        />
                        {validation.contact && <FormHelperText error>{validation.contact}</FormHelperText>}
                      </FormLabel>
                    </Group>

                    <Group fullWidth wrap={false} valign="start">
                      <FormLabel
                        data-testid="date-photographed"
                        fullWidth
                        required={requiredFields.datePhotographed}
                        label="Date Photographed"
                      >
                        <Calendar
                          disabled={isBulkEditFormOpen}
                          error={calendarError || validation.datePhotographed}
                          maxDate={new Date()}
                          calendarInputProps={{
                            componentRef: calendarRef,
                            onBlur: () => {
                              onBlur({ datePhotographed: '' });
                            },
                            onChange: () => {
                              if (calendarRef.current && calendarRef.current.value === '') {
                                onBlur({ datePhotographed: '' });
                              }
                            },
                          }}
                          onError={(error: string) => {
                            SetCalendarError(error ? error : '');
                          }}
                          onSelect={(date: Date) => {
                            // don't use the hook for managing state - multiple calendars rendered at same time effect same state
                            if (date) {
                              if (
                                helpers.parseDate(format(date, constants.DATE_FORMATS.CLOUDINARY)) > helpers.getToday()
                              ) {
                                onBlur({ datePhotographed: 'Date cannot be bigger than today' });
                              } else {
                                onBlur({
                                  datePhotographed: date ? format(date, constants.DATE_FORMATS.CLOUDINARY) : '',
                                });
                              }
                            }
                          }}
                          placeholder={`${constants.DATE_FORMATS.UPLOADS.EDIT_METADATA_FORM.toLowerCase()}`}
                          selectedDate={datePhotographed ? helpers.parseDate(datePhotographed as string) : null}
                        />
                      </FormLabel>
                      <FormLabel fullWidth label="Type">
                        <Select
                          data-testid="type-select"
                          disabled={isBulkEditFormOpen}
                          name="Type"
                          onChange={({ target }: { target: HTMLInputElement }) =>
                            onChange({ graphicType: target.value as GraphicType })
                          }
                          value={graphicType}
                        >
                          {constants.GRAPHIC_TYPES.map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}
                        </Select>
                      </FormLabel>
                    </Group>
                    <FormLabel fullWidth label={<AltTextTooltip />} required={requiredFields.altText}>
                      <Textarea
                        data-testid="altText-input"
                        disabled={isBulkEditFormOpen}
                        error={!!validation.altText}
                        onBlur={({ target }: { target: HTMLInputElement }) => {
                          onBlur({ altText: target.value.trimEnd() });
                        }}
                        onChange={({ target }: { target: HTMLInputElement }) =>
                          onChange({ altText: target.value.trimStart() })
                        }
                        placeholder="Alt Text - a description of the image for SEO & accessibility"
                        value={altText}
                      />
                      {validation.altText && <FormHelperText error>{validation.altText}</FormHelperText>}
                    </FormLabel>

                    {(resizeOnly || canSeeAiCaptioning) && (
                      <Group align="space-between">
                        {resizeOnly && (
                          <Group.Item>
                            <Group gap="sm" data-testid="padding-color">
                              <PaddingColor background={background} onChange={onChange} disabled={isBulkEditFormOpen} />
                            </Group>
                          </Group.Item>
                        )}
                        {canSeeAiCaptioning && (
                          <Group.Item>
                            <FormLabel label="Enable AI Image Captioning" labelPosition="right">
                              <Checkbox
                                onChange={({ target }: { target: HTMLInputElement }) =>
                                  onChange({ aiCaptioning: target.checked })
                                }
                                checked={aiCaptioning}
                              />
                            </FormLabel>
                          </Group.Item>
                        )}
                      </Group>
                    )}
                  </Group>
                </Group>
              )}
            </Responsive>
          </Box>

          <Box attached="bottom" padding={{ horizontal: 'md', vertical: 'sm' }}>
            <Group align="space-between" wrap={false}>
              <Button data-testid="remove-btn" icon={IconTrash} onClick={onDelete} tertiary>
                Remove
              </Button>
              <Group>
                <FormLabel label="One-Time Use" labelPosition="right" margin={{ right: 'sm' }}>
                  <Switch
                    data-testid="one-time-use"
                    checked={oneTimeUse}
                    disabled={isBulkEditFormOpen}
                    name={filename}
                    id={filename}
                    onChange={({ target }: { target: HTMLInputElement }) =>
                      onChange({ oneTimeUse: target.checked, caption: getOneTimeUse(oneTimeUse) })
                    }
                  />
                </FormLabel>
                <FormLabel label="Resize Only" labelPosition="right" margin={{ right: 'sm' }}>
                  <Switch
                    data-testid="resize-only"
                    checked={resizeOnly}
                    disabled={isBulkEditFormOpen}
                    name={filename}
                    id={filename}
                    onChange={({ target }: { target: HTMLInputElement }) => onChange({ resizeOnly: target.checked })}
                  />
                </FormLabel>
              </Group>
            </Group>
          </Box>
        </Wrapper>
      )}
    </Wrapper>
  );
}

export default EditMetadataCard;
